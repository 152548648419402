<template>
  <div>
    <portal to="page-top-title">Campaigns</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'campaigns-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/campaigns" :url-query="query" data-prop="campaigns" :dataCallback="dataCallback" loader @request-after="requestAfter">
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Type</th>
          <th>Name</th>
          <th>Labels</th>
          <th>Apis</th>
          <th>Offers</th>
          <th class="text-center">Status</th>
          <th>Stats</th>
          <th class="text-right">Dates</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="campaign in campaigns" :key="`campaigns-tr-${campaign.id}`" :value="campaign">
          <td>{{ campaign.id }}</td>
          <td>{{ campaign.type_text }}</td>
          <td>{{ campaign.name }}</td>
          <td>
            <em class="text-info" v-if="!campaign.labels.length">None</em>
            <div class="td-rows comma" v-else>
              <div class="td-row" v-for="label in campaign.labels" :key="`campaigns-tr-${campaign.id}-label-${label.id}`">
                <router-link :to="{ name: 'campaigns-labels-single', params: { id: label.id } }">{{ label.name }}</router-link>
              </div>
            </div>
          </td>
          <td>
            <em class="text-info" v-if="!campaign.apis.length">None</em>
            <div class="td-rows comma" v-else>
              <div class="td-row" v-for="api in campaign.apis" :key="`campaigns-tr-${campaign.id}-api-${api.id}`">
                <router-link :to="{ name: 'apis-single', params: { id: api.id } }">{{ api.name }}</router-link>
              </div>
            </div>
          </td>
          <td>
            <em class="text-info" v-if="!campaign.offers.length">None</em>
            <div class="td-rows comma" v-else>
              <div class="td-row" v-for="offer in campaign.offers" :key="`campaigns-tr-${campaign.id}-offer-${offer.id}`">
                <router-link :to="{ name: 'offers-single', params: { id: offer.id } }">{{ offer.name }}</router-link>
              </div>
            </div>
          </td>
          <td class="text-center">
            <badge-status :value="campaign.status_text"/>
          </td>
          <td>
            <stats-column :stats="campaign.stats"/>
          </td>
          <td class="text-right">
            <div class="td-rows columns divider">
              <div class="td-row">
                <span class="left">Created At:</span>
                <span class="right">{{ campaign.created_at }}</span>
              </div>
              <div class="td-row">
                <span class="left">Starts At:</span>
                <span class="right">
                  <nullable :value="campaign.starts_at"/>
                </span>
              </div>
              <div class="td-row">
                <span class="left">Finished At:</span>
                <span class="right">
                  <nullable :value="campaign.finished_at"/>
                </span>
              </div>
              <div class="td-row">
                <span class="left">Time Passed:</span>
                <span class="right">
                  <time-passed :start="new Date(campaign.finished_at)"/>
                </span>
              </div>
            </div>
          </td>
          <td class="text-right">
            <btn v-if="campaign.metadata.actions.cancel" icon="fa-ban" color="danger" @click.stop.native="confirmAction(campaign.id, 'cancel')">Cancel</btn>
            <btn v-if="campaign.metadata.actions.pause" icon="fa-pause" color="warning" @click.stop.native="confirmAction(campaign.id, 'pause')">Pause</btn>
            <btn v-if="campaign.metadata.actions.resume" icon="fa-play" color="success" @click.stop.native="confirmAction(campaign.id, 'resume')">Resume</btn>
            <btn v-if="campaign.metadata.editable.this" :route="{ name: 'campaigns-edit', params: { id: campaign.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'campaigns-duplicate', params: { id: campaign.id } }"  icon="fa-copy">Duplicate</btn>
            <btn :route="{ name: 'campaigns-single', params: { id: campaign.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Nullable from '@/views/components/simple/Nullable'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import StatsColumn from '@/views/components/stats/StatsColumn'
import TimePassed from '@/views/components/simple/TimePassed'
import Action from '@/modules/utils/Action'

export default {
  name: 'CampaignsList',
  metaInfo: {
    title: 'Campaigns'
  },
  components: {
    BoxTable,
    Btn,
    Nullable,
    BadgeStatus,
    StatsColumn,
    TimePassed
  },
  data () {
    return {
      query: {
        with: [
          'labels:id,name',
          'apis:id,name',
          'offers:id,name'
        ]
      },
      campaigns: []
    }
  },
  methods: {
    dataCallback (data) {
      return data.map((item) => { item.stats = undefined; return item })
    },
    requestAfter () {
      this.loadCampaignStats(
        this.campaigns.map((campaign) => campaign.id)
      )
    },
    loadCampaignStats (ids) {
      this.$http
        .get('/campaigns/stats', { params: { ids: ids } })
        .then((res) => {
          const { data: { data } } = res

          this.campaigns.forEach((campaign) => {
            campaign.stats = data.find((stats) => stats.id === campaign.id)
          })
        })
    },
    confirmAction (id, action) {
      Action.confirm({
        id: id,
        action: action,
        name: 'Campaign',
        route: '/campaigns/:id/action',
        collection: this.campaigns
      })
    }
  }
}
</script>
